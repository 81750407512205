<template>
  <div class="container page">
    <van-nav-bar :title="$t('setting.fill_bank')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="main-box">
      <div class="label">{{$t("setting.fill_bank_tip")}}</div>
      <van-cell-group>
        <van-field v-model="bank" :label="$t('setting.band_name')" readonly :placeholder="$t('setting.band_name_tip')" @click="showSelectBanks()"/>
        <van-field v-model="bankid" :label="$t('setting.band_account')" type="digit" :placeholder="$t('setting.band_account_tip')" />
        <van-field v-model="username" :label="$t('setting.username')" :placeholder="$t('setting.username_place')" />
        <!-- <van-field v-model="mobile" :label="$t('setting.mobile')" type="digit" :placeholder="$t('setting.mobile_place')" /> -->
      </van-cell-group>
      <p>{{$t("setting.bank_warn")}}</p>
    </div>
    <van-button class="bindCard" :disabled="isDisabled" type="default" @click="bindCard()">{{$t("setting.bank_ok")}}</van-button>
    <van-popup v-model="showBank" round position="bottom" :style="{ height: '35%' }" >
      <van-picker
          show-toolbar
          :confirm-button-text="$t('my.confirm')"
          :cancel-button-text="$t('my.cancel')"
          :columns="banks"
          @confirm="onConfirm"
          @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      banks: [],
      showBank:false,
      userInfo:{},
      bankid:"",
      username:"",
      mobile:"",
      bank:"",
      bank_code: "",
      isDisabled:false
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    bindCard(){

      if(this.userInfo.bankid){
        this.$toast(this.$t("setting.band_account_tip"));
        return true;
      }
      if(this.bank === "" || this.bank === null || this.bank === undefined){
        this.$toast.fail(this.$t("setting.band_name_tip"));
        return false;
      }

      if(this.userInfo.bankid){
        this.$toast(this.$t("setting.band_account_tip"));
        return true;
      }

      if(this.username===""){
        this.$toast(this.$t("setting.username_place"));
        return false;
      }
      this.isDisabled = true

      // if(this.mobile===""){
      //   this.$toast(this.$t("setting.mobile_place"));
      //   return false;
      // }

      this.$http({
        method: 'post',
        data:{
          bankid:this.bankid,
          bank:this.bank,
          bank_code: this.bank_code,
          username:this.username,
          mobile:this.mobile
        },
        url: 'user_set_bank'
      }).then(res=>{
        if(res.code === 200){
          this.$router.push({path:'/Mine'})
          this.$toast(res.msg);
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
        this.isDisabled = false
      })
    },
    showSelectBanks(){
      this.showBank = true;
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.name = res.data.name;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getBankList(){
      this.$http({
        method: 'get',
        url: 'sys_get_banks'
      }).then(res=>{
        if(res.code === 200){
          this.banks = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    onConfirm(value) {
      this.bank = value.text
      this.bank_code = value.value
      this.showBank = false;
    },
    onCancel() {
      this.showBank = false;
    },
    getUserBankInfo(){
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res=>{
        if(res.code === 200){
          if(res.data.is_bank){
            this.is_bind = true;
          }else {
            this.is_bind = false;
          }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
      this.getBankList();
      this.getUserBankInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.van-cell {
  font-size: 32px;
  line-height: 80px;
}
.van-hairline--bottom::after {
  border-bottom-width: 3px;
}
.bankbox{
  padding: 15px;
  color: #000;
  background-color: #fff;
}
.bankbox .title{
  padding: 8px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 28px;
}
.main-box{
  background: #fff;

}
.main-box .label{
  padding: 20px;
  font-size: 35px;
  color: #797878;
}
::v-deep .van-picker__toolbar {
  height: 80px;
}
::v-deep .van-picker__cancel, .van-picker__confirm {
  padding: 0 20px;
  font-size: 20px;
}
::v-deep .van-picker-column {
  font-size: 40px;
}
.main-box p{
  padding: 0 20px;
  font-size: 30px;
  color: #ee0a24;
}
.bindCard {
  margin: 20px 30px 0;
  height: 80px;
  line-height: 1.22667rem;
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(
      90deg,#2e2d2b,#2e2d2b);
}
/deep/.van-picker__confirm,/deep/.van-picker__cancel{
  font-size: 36px;
}
</style>
